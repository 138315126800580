import { tw } from '@/utils/tw';
import { default as BaseBreadcrumbsTheme } from 'base/components/Breadcrumbs/theme';

const Breadcrumbs = tw.theme({
  extend: BaseBreadcrumbsTheme,
  slots: {
    step: `text-gray-600 text-headline-xs`,
    separator: `text-gray-600 text-headline-xs`,
  },
});
export default Breadcrumbs;
