import { tw } from '@/utils/tw';
import { default as BaseVideoBaseContent } from 'base/components/VideoBaseContent/theme';

const CoverBox = tw.theme({
  extend: BaseVideoBaseContent.CoverBox,
  slots: {
    base: 'md:text-body-md text-body-sm',
    toggle: 'md:text-body-md text-body-sm',
    headline: 'text-headline-sm md:text-headline-md',
  },
});

const Breadcrumbs = tw.theme({
  extend: BaseVideoBaseContent.Breadcrumbs,
});

const TrendingMenu = tw.theme({
  extend: BaseVideoBaseContent.TrendingMenu,
});
const VideoBaseContent = tw.theme({
  extend: BaseVideoBaseContent,
});

export default Object.assign(VideoBaseContent, { CoverBox, TrendingMenu, Breadcrumbs });
