import { ProseTheme } from '@/components/Prose/theme';
import { tw } from '@/utils/tw';

const Prose = tw.theme({
  extend: ProseTheme,
  base: 'prose-coverbox',
});

const CoverBox = tw.theme({
  slots: {
    base: [
      'relative',
      'group/coverbox',
      'grid',
      'auto-rows-min',
      'gap-y-2',
      'gap-x-6',
      'bg-primary-100',
      'wings',
      'wings-primary-100',
      'data-[icon]:grid-cols-[1fr_auto]',
      'md:data-[icon]:grid-cols-[auto_auto_1fr]',
    ],
    collapse: 'relative peer-checked:line-clamp-2',
    group: 'content-center md:row-span-2',
    headline: 'content-center whitespace-nowrap text-black text-headline-sm sm:text-headline-md',
    icon: 'col-start-2 row-span-2 row-start-1 md:col-start-1 md:row-span-1',
    images: '',
    toggle: [
      'absolute',
      'bottom-0',
      'right-0',
      'cursor-pointer',
      'bg-primary-100',
      'pl-3',
      'text-right',
      'text-primary-700',
      'text-body-xs',
      'sm:text-body-md',
      'hover:underline',
      'invisible',
      'group-data-[collapsed]/coverbox:visible',
    ],
  },
});

export default Object.assign(CoverBox, { Prose });
